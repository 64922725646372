import React from "react";

class Partner extends React.Component {
    render() {
        return (
            <div className={'partner'}>
                <img src={this.props.logo} alt={this.props.title}/>
            </div>
        );
    }
}

export default Partner;
