import React from "react";
import Partner from "../components/partner";

import Michelin from '../images/partners/michelin.png'
import Telekom from '../images/partners/telekom.png'
import Unil from '../images/partners/unil.png'
import Agoric from '../images/partners/agoric.png'
import Aisb from '../images/partners/aisb.svg'
import Kleber from '../images/partners/kleber.png'
import Turfgrass from '../images/partners/pannon-turfgrass.png'
import CocaCola from '../images/partners/coca-cola.png'
import ISB from '../images/partners/isb.svg'
import Interchain from '../images/partners/interchain.svg'
import IBC from '../images/partners/ibc.svg'
import Celestia from '../images/partners/celestia.svg'
import Talus from '../images/partners/talus.svg'
import Cudos from '../images/partners/cudos.svg'
import Nesa from '../images/partners/nesa.svg'
import Penumbra from '../images/partners/penumbra.svg'

class Partners extends React.Component {
    render() {
        return (
            <section className={'partners-section'}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={'col-12 pb-3'}>
                            <h2>These are our clients. <br/>
                                This is who we work with.</h2>
                        </div>
                        <div className={'col-12'}>
                            <div className={'row mt-md-5 align-items-center'}>
                                <div className={'col-6 col-md-3 col-lg-3 py-4 py-md-5'}>
                                    <Partner title={'Michelin'} logo={Michelin}/>
                                </div>
                                <div className={'col-6 col-md-3 col-lg-3 py-4 py-md-5'}>
                                    <Partner title={'Telekom'} logo={Telekom}/>
                                </div>
                                <div className={'col-6 col-md-3 col-lg-3 py-4 py-md-5'}>
                                    <Partner title={'Coca-Cola'} logo={CocaCola}/>
                                </div>
                                <div className={'col-6 col-md-3 col-lg-3 py-4 py-md-5'}>
                                    <Partner title={'Kleber'} logo={Kleber}/>
                                </div>
                                <div className={'col-6 col-md-3 col-lg-3 py-4 py-md-5'}>
                                    <Partner title={'Pannon Turfgrass'} logo={Turfgrass}/>
                                </div>
                                <div className={'col-6 col-md-3 col-lg-3 py-4 py-md-5'}>
                                    <Partner title={'Université de Lausanne'} logo={Unil}/>
                                </div>
                                <div className={'col-6 col-md-3 col-lg-3 py-4 py-md-5'}>
                                    <Partner title={'ISB'} logo={ISB}/>
                                </div>
                                <div className={'col-6 col-md-3 col-lg-3 py-4 py-md-5'}>
                                    <Partner title={'American International School of Budapest'} logo={Aisb}/>
                                </div>
                                <div className={'col-6 col-md-3 col-lg-3 py-4 py-md-5'}>
                                    <Partner title={'Interchain Foundation'} logo={Interchain}/>
                                </div>
                                <div className={'col-6 col-md-3 col-lg-3 py-4 py-md-5'}>
                                    <Partner title={'IBC'} logo={IBC}/>
                                </div>
                                <div className={'col-6 col-md-3 col-lg-3 py-4 py-md-5'}>
                                    <Partner title={'Celestia'} logo={Celestia}/>
                                </div>
                                <div className={'col-6 col-md-3 col-lg-3 py-4 py-md-5'}>
                                    <Partner title={'Agoric'} logo={Agoric}/>
                                </div>
                                <div className={'col-6 col-md-3 col-lg-3 py-4 py-md-5'}>
                                    <Partner title={'Talus'} logo={Talus}/>
                                </div>
                                <div className={'col-6 col-md-3 col-lg-3 py-4 py-md-5'}>
                                    <Partner title={'Cudos'} logo={Cudos}/>
                                </div>
                                <div className={'col-6 col-md-3 col-lg-3 py-4 py-md-5'}>
                                    <Partner title={'Nesa'} logo={Nesa}/>
                                </div>
                                <div className={'col-6 col-md-3 col-lg-3 py-4 py-md-5'}>
                                    <Partner title={'Penumbra'} logo={Penumbra}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Partners;
