import React from 'react';
import Branding from "./services/branding";
import Web from "./services/web";
import Apps from "./services/apps";
import Video from "./services/video";

const HomeServices = () => {
    return (
        <div className={'services-subpage'} style={{paddingTop:0}}>
            <section className={'services-section'}>
                <div className={'container'}>

                    <div className="row">
                        <div className={'col-12 col-lg-6 offset-lg-6 ps-lg-4'}>
                            <ul className="nav nav-pills" id="pills-tab" style={{marginBottom:'50px'}}>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" data-text={'Branding'} id="pills-branding-tab" data-bs-toggle="pill"
                                            data-bs-target="#pills-branding" type="button" role="tab"
                                            aria-controls="pills-branding" aria-selected="true">Branding
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" data-text={'Web'} id="pills-web-tab" data-bs-toggle="pill"
                                            data-bs-target="#pills-web" type="button" role="tab"
                                            aria-controls="pills-web" aria-selected="false">Web
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" data-text={'Apps'} id="pills-apps-tab" data-bs-toggle="pill"
                                            data-bs-target="#pills-apps" type="button" role="tab"
                                            aria-controls="pills-apps" aria-selected="false">Apps
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" data-text={'Video'} id="pills-video-tab" data-bs-toggle="pill"
                                            data-bs-target="#pills-video" type="button" role="tab"
                                            aria-controls="pills-video" aria-selected="false">Video
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>


                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-branding" role="tabpanel"
                             aria-labelledby="pills-branding-tab">
                            <Branding accordion={false}/>
                        </div>
                        <div className="tab-pane fade" id="pills-web" role="tabpanel"
                             aria-labelledby="pills-web-tab">
                            <Web accordion={false}/>
                        </div>
                        <div className="tab-pane fade" id="pills-apps" role="tabpanel"
                             aria-labelledby="pills-apps-tab">
                            <Apps accordion={false}/>
                        </div>
                        <div className="tab-pane fade" id="pills-video" role="tabpanel"
                             aria-labelledby="pills-video-tab">
                            <Video accordion={false}/>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default HomeServices;